import { Form, Link, useOutletContext } from "@remix-run/react";
import { useCart } from "../../context/CartContext";
import { useBusiness } from "../../context/BusinessContext";
import { NATIONWIDE_COLLECTIONS } from "../../config/constants";
import SearchForm from "./SearchForm";
import { Switch, Field, Label } from "@headlessui/react";
import { useEffect, useState } from "react";
import OurisFoodBanner from "./OurisFoodBanner";
import React from "react";

export default function Header({ hideBusinessSelection = false }) {
  const { productCount, cartLoaded, cartIsEmpty, setCartLocked, clearCart } =
    useCart();
  const { collections, user, brand, terminology } = useOutletContext();
  const { setIsBusinessModalOpen } = useBusiness();
  const [enabled, setEnabled] = useState(
    user?.business === "nationwide" ? true : false
  );

  const filteredCollections = collections.filter((item) =>
    NATIONWIDE_COLLECTIONS.includes(item.id)
  );

  const linklist =
    user?.business && user.business === "nationwide"
      ? [...filteredCollections]
      : [...collections];

  useEffect(() => {
    if (user && user.business === "nationwide") {
      setEnabled(true);
    }
  }, []);

  const handleBusinessSwitch = async (enabled) => {
    const isConfirmed = window.confirm(
      "Changing your delivery option will empty your cart. Are you sure you want to proceed?"
    );

    if (isConfirmed) {
      setEnabled(enabled);

      try {
        setCartLocked(true);
        // Make the POST request with application/json
        const response = await fetch("/api/set-business", {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Send as JSON
          },
          body: JSON.stringify({
            business: enabled === true ? "nationwide" : "local",
          }),
        });

        // Handle the response
        if (response.ok) {
          clearCart();
          localStorage.removeItem("cart");
          setCartLocked(false);
          window.location.href = "/";
        }
      } catch (error) {
        console.error("Error occurred during the request", error);
      } finally {
        // Unlock the cart regardless of success or failure
        setCartLocked(false);
      }
    } else {
      //set is Loading?
      console.log("Business change cancelled by the user");
    }
  };
  return (
    <>
      {terminology?.announcement ? (
        <div className="text-[14px] leading-[16px] flex gap-1 justify-center items-center bg-primary text-black py-3 px-4 text-center">
          {terminology.announcement}
        </div>
      ) : null}
      <header className="relative bg-black z-10">
        <div className="pt-0 lg:pt-[14px] pb-0">
          <div className="max-w-[1506px] mx-auto px-[10px] lg:px-8 2xl:px-0">
            <div className="grid grid-cols-12 gap-4 lg:gap-8 auto-cols-fr w-full items-center justify-center">
              <div className="col-span-9 lg:col-span-2 flex justify-start">
                <div className="flex flex-row lg:flex-col items-center gap-3 lg:gap-0 lg:items-start">
                  <div>
                    <Link
                      to="/"
                      reloadDocument
                      className="flex pt-3 pb-3 lg:pb-0 lg:pt-0"
                    >
                      <img
                        src={brand.data.logo}
                        alt="store-logo"
                        className="max-h-[50px]"
                      />
                    </Link>
                  </div>

                  {!hideBusinessSelection ? (
                    <Field className="flex items-center mt-0 lg:mt-2">
                      <Switch
                        name="business"
                        checked={enabled}
                        onChange={(enabled) => {
                          handleBusinessSwitch(enabled);
                        }}
                        className="group inline-flex h-[22px] w-[40px] items-center rounded-full bg-white/20 border-2 border-white/30 transition data-[checked]:bg-black data-[checked]:border-2 data-[checked]:border-primary data-[checked]:bg-primary/30"
                      >
                        <span className="size-3 translate-x-[4px] rounded-full bg-white transition group-data-[checked]:translate-x-[20px]" />
                      </Switch>
                      <Label className="text-white uppercase text-[11px] leading-[11px] ml-1 tracking-[1px] w-[100px]">
                        {!enabled ? "Local Delivery" : "Nationwide Shipping"}
                      </Label>
                    </Field>
                  ) : null}
                  {/* <button
                  type="button"
                  onClick={() => {
                    setIsBusinessModalOpen(true);
                  }}
                  className="relative flex text-white items-center justify-center gap-1 py-1 px-1 rounded-lg uppercase text-xs tracking-widest mt-1 text-center w-full"
                >
                  <span className="h-[6px] w-[6px] rounded-full bg-green"></span>
                  {!user || !user.business ? "Local" : user.business}
                </button> */}
                </div>
              </div>
              <div className="col-span-8 hidden lg:flex justify-center">
                <div className="flex gap-2 lg:px-0 w-full">
                  <div className="relative bg-[#333333] rounded-lg flex-grow">
                    <SearchForm />
                  </div>
                </div>
              </div>

              <div className="col-span-3 lg:col-span-2 flex justify-end">
                <div className="flex gap-4 items-center">
                  <div className="text-white">
                    <Link to="/account">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-8"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                        />
                      </svg>
                    </Link>
                  </div>
                  <div className="relative">
                    <Link
                      to="/cart"
                      prefetch="render"
                      className="text-white hover:text-gold focus:text-gold"
                    >
                      <svg
                        fill="none"
                        viewBox="0 0 19 17"
                        width="30"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L2.87153 1.62385C3.56699 1.85566 4.07989 2.44939 4.2082 3.17114L5.30668 9.3501C5.47638 10.3046 6.30628 11 7.2758 11H14.896C15.7868 11 16.5702 10.4109 16.8174 9.5551L18.2619 4.55508C18.6313 3.27618 17.6716 2 16.3404 2H7M8 14.5C8 15.3284 7.32843 16 6.5 16C5.67157 16 5 15.3284 5 14.5C5 13.6716 5.67157 13 6.5 13C7.32843 13 8 13.6716 8 14.5ZM16 14.5C16 15.3284 15.3284 16 14.5 16C13.6716 16 13 15.3284 13 14.5C13 13.6716 13.6716 13 14.5 13C15.3284 13 16 13.6716 16 14.5Z"
                          style={{
                            strokeLinecap: "round",
                            strokeWidth: "1.25",
                            stroke: "currentcolor",
                          }}
                        ></path>
                      </svg>
                      {cartLoaded && !cartIsEmpty ? (
                        <span className="bg-white text-black w-[20px] h-[20px] text-xs absolute leading-[12px] -right-[7px] -top-[7px] rounded-[50%] flex items-center justify-center">
                          {productCount}
                        </span>
                      ) : null}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-[1506px] mx-auto px-[10px] lg:px-8 2xl:px-0">
            <div className="border-top-0">
              <div className="grid grid-cols-12 gap-4 lg:gap-8 auto-cols-fr w-full">
                <div className="col-span-12 lg:col-span-12">
                  {/* {user.business === "nationwide" ? (
                    <div className="text-white mb-2 flex lg:hidden">
                      <Link to="/collections/29839/29846">
                        🇺🇸 USDA-Certified American Prime Beef
                      </Link>
                    </div>
                  ) : null} */}
                  <div className="hidden lg:flex gap-1 w-full justify-between items-center">
                    {linklist &&
                      linklist.length > 0 &&
                      linklist.map((link, index) => {
                        const childlinks = link.sub_category;

                        return (
                          <React.Fragment key={link.id}>
                            <div className="group flex-grow cursor-pointer">
                              <Link
                                to={`/collections/${link.id}`}
                                className="flex justify-center items-center gap-1 text-xs xl:text-base px-3 py-3 rounded-lg text-white capitalize group-hover:text-primary hover:text-primary pl-0"
                              >
                                {link.title}
                                {childlinks.length > 1 ? (
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                  >
                                    <polyline
                                      fill="none"
                                      stroke="currentcolor"
                                      strokeWidth="1.03"
                                      points="16 7 10 13 4 7"
                                    ></polyline>
                                  </svg>
                                ) : null}
                              </Link>
                              {childlinks.length > 1 ? (
                                <div
                                  className={`absolute left-0 right-0 bottom-0 translate-y-full hidden group-hover:block bg-black ${
                                    link.id !== "29760" ? "hidden" : null
                                  }`}
                                >
                                  <div className="relative max-w-[1506px] mx-auto px-[10px] lg:px-8 2xl:px-0">
                                    <div className="pb-4">
                                      <div className="grid grid-cols-12 gap-2 auto-cols-fr w-full">
                                        {childlinks.map((childlink) => {
                                          return (
                                            <div
                                              key={childlink.id}
                                              className="col-span-3"
                                            >
                                              <Link
                                                to={`/collections/${link.id}/${childlink.id}`}
                                                className="px-3 py-2 flex truncate max-w-ful bg-white bg-opacity-10 text-white rounded-lg hover:bg-primary hover:text-black transition-all duration-300"
                                              >
                                                {childlink.title}
                                              </Link>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </React.Fragment>
                        );
                      })}
                  </div>
                  <div className="flex lg:hidden gap-2 lg:px-0 w-full mb-2">
                    <div className="relative bg-[#333333] rounded-lg flex-grow">
                      <SearchForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
